import {
  call,
  takeEvery,
  takeLatest,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { post, get } from 'api'
import {
  gaActions,
  PURCHASE_COMPLETED,
  SHOW_CALENDAR,
  RESERVE_ORDER,
  CONFIRM_ORDER,
  CLICK_PAYMENT_PROVIDER,
} from 'googleTagManager'
import {
  slice,
  confirmOrderSuccess,
  confirmOrderFailed,
  setService,
  setTransaction,
  setOrder,
} from './ducks'

const payOrder = id => post(`/orders/${id}/pay`, {})
const getOrder = id => get(`/orders/${id}`)
const postOrder = order => post('/orders', order)

function* initPaymentSaga(action) {
  try {
    const transaction = yield call(payOrder, action.payload)
    yield put(setTransaction(transaction))
  } catch (e) {
    // using global error handler
  }
}

function* fetchOrderSaga(action) {
  try {
    const order = yield call(getOrder, action.payload)
    yield put(setOrder(order))
  } catch (e) {
    // using global error handler
  }
}

function* confirmOrder(history, action) {
  try {
    const { operator } = yield select(s => s.user.user)
    const order = yield call(postOrder, action.payload)
    yield put(confirmOrderSuccess(order))
    yield put(setService({}))
    const { id, price } = order
    const endpoint = operator || price === 0 ? 'confirmed' : 'payment'
    yield call(history.push, `/orders/${id}/${endpoint}`)
  } catch (e) {
    // using global error handler
    yield put(confirmOrderFailed(e.message))
  }
}

function* analyticsSaga(type, action) {
  const { operator } = yield select(s => s.user.user)
  if (!operator) {
    yield put(gaActions[type](action.payload))
  }
}

export default function* ordersSagas(context) {
  yield all([
    takeEvery(`${slice}/initPayment`, initPaymentSaga),
    takeEvery(`${slice}/fetchOrder`, fetchOrderSaga),
    takeLatest(`${slice}/confirmOrder`, confirmOrder, context.history),
    // analytics
    takeEvery(`${slice}/showCalendar`, analyticsSaga, SHOW_CALENDAR),
    takeEvery(`${slice}/reserve`, analyticsSaga, RESERVE_ORDER),
    takeEvery(`${slice}/confirmOrderSuccess`, analyticsSaga, CONFIRM_ORDER),
    takeEvery(
      `${slice}/trackPaymentProvider`,
      analyticsSaga,
      CLICK_PAYMENT_PROVIDER,
    ),
    takeEvery(`${slice}/orderPaid`, analyticsSaga, PURCHASE_COMPLETED),
  ])
}
