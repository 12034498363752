import { createMiddleware, createMetaReducer } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'

const emitUserId = action => ({
  event: 'login',
  userId: action.payload.id,
})

export const SHOW_CALENDAR = 'showCalendar'
const showCalendar = action => ({
  event: SHOW_CALENDAR,
  ecommerce: {
    detail: {
      actionField: {
        step: 1,
        list: action.payload.service_group_id,
      },
      products: [
        {
          id: action.payload.id,
          name: action.payload.name,
          category: action.payload.service_group_name,
        },
      ],
    },
  },
})

export const RESERVE_ORDER = 'reserveOrder'
const reserveOrder = action => ({
  event: RESERVE_ORDER,
  ecommerce: {
    add: {
      actionField: {
        step: 2,
        revenue: action.payload.price,
        list: action.payload.service_group_id,
      },
      products: [
        {
          id: action.payload.service_id,
          name: action.payload.service_name,
          price: action.payload.price,
          category: action.payload.service_group_name,
        },
      ],
    },
  },
})

export const CONFIRM_ORDER = 'confirmOrder'
const confirmOrder = action => ({
  event: CONFIRM_ORDER,
  ecommerce: {
    checkout: {
      actionField: {
        step: 3,
        id: action.payload.id,
        revenue: action.payload.price,
        list: action.payload.service_group_id,
      },
      products: [
        {
          id: action.payload.service_id,
          name: action.payload.service_name,
          price: action.payload.price,
          category: action.payload.service_group_name,
        },
      ],
    },
  },
})

export const CLICK_PAYMENT_PROVIDER = 'clickPaymentProvider'
const clickPaymentProvider = action => ({
  event: CLICK_PAYMENT_PROVIDER,
  ecommerce: {
    checkout_option: {
      actionField: {
        step: 4,
        id: action.payload.id,
        option: action.payload.providerName,
        list: action.payload.service_group_id,
      },
      products: [
        {
          id: action.payload.service_id,
          name: action.payload.service_name,
          price: action.payload.price,
          category: action.payload.service_group_name,
        },
      ],
    },
  },
})
export const PURCHASE_COMPLETED = 'purchaseCompleted'
const purchaseCompleted = action => ({
  event: PURCHASE_COMPLETED,
  ecommerce: {
    purchase: {
      actionField: {
        id: action.payload.id,
        step: 5,
        revenue: action.payload.price,
        list: action.payload.service_group_id,
      },
      products: [
        {
          id: action.payload.service_id,
          name: action.payload.service_name,
          price: action.payload.price,
        },
      ],
    },
  },
})

const emitters = {
  showCalendar,
  reserveOrder,
  confirmOrder,
  clickPaymentProvider,
  purchaseCompleted,
}

const makeActionCreator = type => payload => ({ type, payload })

const actions = [
  SHOW_CALENDAR,
  RESERVE_ORDER,
  CONFIRM_ORDER,
  CLICK_PAYMENT_PROVIDER,
  PURCHASE_COMPLETED,
]

const makeAction = type => `ga/${type}`

export const gaActions = actions.reduce(
  (obj, type) => ({ ...obj, [type]: makeActionCreator(makeAction(type)) }),
  {},
)

const eventsMap = {
  'user/loginSuccess': emitUserId,
  ...actions.reduce(
    (obj, type) => ({ ...obj, [makeAction(type)]: emitters[type] }),
    {},
  ),
}

const gtm = GoogleTagManager()
export const gtmMiddleware = createMiddleware(eventsMap, gtm)
export const gtmMetaReducer = createMetaReducer(eventsMap, gtm)
